<template>
    <div>
        <b-sidebar
            id="sidebar-task-handler"
            sidebar-class="sidebar-lg"
            :visible="isTicketHandlerSidebarActive"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
            @change="val => $emit('update:is-ticket-handler-sidebar-active', val)"
            @hidden="clearForm"
        >
            <template #default="{ hide }">
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <!-- <b-button
                        v-if="ticketStatusLocal.id"
                        size="sm"
                        :variant="ticketStatusLocal.isCompleted ? 'outline-success' : 'outline-secondary'"
                        @click="ticketStatusLocal.isCompleted = !ticketStatusLocal.isCompleted"
                    >
                        {{ ticketStatusLocal.isCompleted ? "Completed" : "Mark Complete" }}
                    </b-button> -->
                    <h5 v-if="!ticketStatusLocal.id" class="mb-0">Add Ticket Status</h5>

                    <div>
                        <feather-icon
                            v-show="ticketStatusLocal.id"
                            icon="TrashIcon"
                            class="cursor-pointer"
                            @click="
                                $emit('remove-ticket-status');
                                hide();
                            "
                        />
                        <!-- <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="StarIcon"
                            size="16"
                            :class="{ 'text-warning': ticketStatusLocal.isImportant }"
                            @click="ticketStatusLocal.isImportant = !ticketStatusLocal.isImportant"
                        /> -->
                        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                    </div>
                </div>

                <!-- Body -->
                <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                    <!-- Form -->
                    <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                        <!-- Status Name -->
                        <validation-provider #default="validationContext" name="Name" rules="required">
                            <b-form-group label="Name" label-for="name">
                                <b-form-input
                                    id="name"
                                    v-model="ticketStatusLocal.name"
                                    autofocus
                                    :state="getValidationState(validationContext)"
                                    trim
                                    placeholder="on-hold"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Label -->
                        <validation-provider #default="validationContext" name="Label" rules="required">
                            <b-form-group label="label" label-for="label">
                                <b-form-input
                                    id="label"
                                    v-model="ticketStatusLocal.label"
                                    :state="getValidationState(validationContext)"
                                    trim
                                    placeholder="On Hold"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Variant -->
                        <validation-provider #default="validationContext" name="Variant" rules="required">
                            <b-form-group label="Variant" label-for="variant">
                                <b-form-input
                                    id="variant"
                                    v-model="ticketStatusLocal.variant"
                                    :state="getValidationState(validationContext)"
                                    trim
                                    placeholder="warning"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                type="submit"
                            >
                                {{ ticketStatusLocal.id ? "Update" : "Add " }}
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="reset"
                                variant="outline-secondary"
                            >
                                Reset
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { toRefs } from "@vue/composition-api";
import useTicketStatusListHandler from "./useTicketStatusListHandler";

export default {
    components: {
        // BSV
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isTicketHandlerSidebarActive",
        event: "update:is-ticket-handler-sidebar-active",
    },
    props: {
        isTicketHandlerSidebarActive: {
            type: Boolean,
            required: true,
        },
        ticket: {
            type: Object,
            required: true,
        },
        clearTicketData: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            required,
            email,
            url,
        };
    },
    setup(props, { emit }) {
        const {
            ticketStatusLocal,
            resetTicketStatusLocal,

            // UI
            onSubmit,
            resolveAvatarVariant,
        } = useTicketStatusListHandler(toRefs(props), emit);

        const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(
            resetTicketStatusLocal,
            props.clearTicketData,
        );

        return {
            // Add New
            ticketStatusLocal,
            onSubmit,

            // Form Validation
            resetForm,
            clearForm,
            refFormObserver,
            getValidationState,

            // UI
            resolveAvatarVariant,

        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
    ::v-deep .vs__dropdown-toggle {
        padding-left: 0;
    }
}

//#quil-content ::v-deep {
//    > .ql-container {
//        border-bottom: 0;
//    }
//
//    + #quill-toolbar {
//        border-top-left-radius: 0;
//        border-top-right-radius: 0;
//        border-bottom-left-radius: $border-radius;
//        border-bottom-right-radius: $border-radius;
//    }
// }
</style>
