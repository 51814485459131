import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        // eslint-disable-next-line no-unused-vars
        fetchTicketStatuses(_, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/admin/ticketStatus", {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addTicketStatus(ctx, statusData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/admin/ticketStatus", statusData)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateTicketStatus(ctx, statusData) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/admin/ticketStatus/${statusData.id}`, statusData)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteTicketStatus(ctx, statusId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/admin/ticketStatus/${statusId}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
    },
};
