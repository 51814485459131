<template>
    <div>
        <!-- Ticket Status Handler -->
        <ticket-status-list-handler
            v-model="isTicketHandlerSidebarActive"
            :ticket="ticketStatus"
            :clear-ticket-data="clearTicketStatusData"
            @remove-ticket="removeTicketStatus"
            @add-ticket="addTicketStatus"
            @update-ticket="updateTicketStatus"
        />

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button variant="primary" @click="isTicketHandlerSidebarActive = true">
                                <span class="text-nowrap">Add Ticket Status</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="fetchTicketStatuses"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: ID -->
                <template #cell(id)="data">
                  <span class="font-weight-bold">#{{ data.item.id}}</span>
                </template>

                <!-- Column: Name -->
                <template #cell(name)="data">
                    <div class="text-nowrap">
                        <b-link
                            class="font-weight-bold d-block text-nowrap"
                            @click="handleTicketStatusClick(data.item)"
                        >
                            {{ data.item.name }}
                        </b-link>
                    </div>
                </template>

                <!-- Column: Variant -->
                <template #cell(variant)="data">
                    <b-badge
                        pill
                        :variant="`light-${data.item.variant}`"
                        class="text-capitalize"
                    >
                        {{ data.item.variant }}
                    </b-badge>
                </template>

                <!-- Column: Create Date -->
                <template #cell(createDate)="data">
                    <span class="text-nowrap text-capitalize">
                        {{ data.item.createDate | formatDate }}
                    </span>
                </template>

                <template #cell(updatedDate)="data">
                    <span class="text-nowrap text-capitalize">
                        {{ data.item.updatedDate | formatDate }}
                    </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item :id="`user-row-${data.item.id}-sync-icon`" @click="syncuser(data.item.id)">
                            <feather-icon icon="RefreshCwIcon" />
                            <span class="align-middle ml-50">Sync</span>
                        </b-dropdown-item>

                        <b-dropdown-item disabled>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-row v-if="totalUsers === 0" class="mx-2 d-flex justify-content-center"
            ><span class="font-weight-bold">No Records Found</span></b-row
            >
            <div v-if="tableLoading" class="text-center my-1">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
            </div>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                        >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
                        >
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol, // BFormInput,
    BButton,
    BTable,
    BSpinner,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import useTicketStatusList from "./useTicketStatusList";
import ticketStatusStoreModule from "./ticketStatusStoreModule";
import TicketStatusListHandler from "@/views/admin/ticket-status/TicketStatusListHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";

export default {
    components: {
        TicketStatusListHandler,
        BCard,
        BRow,
        BCol,
        // BFormInput,
        BSpinner,
        BButton,
        BTable,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const toast = useToast();
        const USER_APP_STORE_MODULE_NAME = "ticket-status";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStatusStoreModule);
        }

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            }
        });

        const blankTicketStatus = {
            name: "",
            label: "",
            variant: "",
        }

        const ticketStatus = ref(JSON.parse(JSON.stringify(blankTicketStatus)));
        const clearTicketStatusData = () => {
            ticketStatus.value = JSON.parse(JSON.stringify(blankTicketStatus));
        };

        const handleTicketStatusClick = ticketStatusData => {
            ticketStatus.value = ticketStatusData;
            isTicketHandlerSidebarActive.value =true;
        }

        const isTicketHandlerSidebarActive = ref(false);

        const departmentOptions = [
            {
                label: "Technology",
                value: "Technology",
            },
            {
                label: "Marketing",
                value: "Marketing",
            },
            {
                label: "Sales",
                value: "Sales",
            },
            {
                label: "Product",
                value: "Product",
            },
        ];

        const {
            fetchTicketStatuses,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,
            tableLoading,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useTicketStatusList();

        const addTicketStatus = val => {
          if(!val) return;
          store.dispatch("ticket-status/addTicketStatus", val)
              .then(()=> {
                  toast({
                      component: ToastificationContent,
                      props: {
                          title: "Ticket Status Added",
                          icon: "CheckCircleIcon",
                          variant: "success"
                      }
                  })
              })
              .then(()=> {
                  refetchData();
              })
              .catch(() => {
                  toast({
                      component: ToastificationContent,
                      props: {
                          title: "Ticket Add Failed",
                          icon: "AlertTriangleIcon",
                          variant: "danger"
                      }
                  })
              });

        };

        const removeTicketStatus = () => {
            store.dispatch("ticket-status/deleteTicketStatus", {id: ticket.value.id})
                .then(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Status Removed",
                            icon: "CheckCircleIcon",
                            variant: "success"
                        }
                    })
                })
                .then(() => {
                    // eslint-disable-next-line no-use-before-define
                    refetchData();
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Status Remove Failed",
                            icon: "AlertTriangleIcon",
                            variant: "danger"
                        }
                    })
                });
        };


        const updateTicketStatus = ticketStatusData => {
            store.dispatch("ticket-status/updateTicketStatus", ticketStatusData)
                .then(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Status Updated",
                            icon: "CheckCircleIcon",
                            variant: "success"
                        }
                    });
                })
                .then(() => {
                    // eslint-disable-next-line no-use-before-define
                    refetchData();
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Ticket Status Update Failed",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                })
        };




        return {
            // Sidebar
            isTicketHandlerSidebarActive,

            fetchTicketStatuses,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,
            tableLoading,
            // Filter
            avatarText,

            departmentOptions,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,

            // actions
            addTicketStatus,
            removeTicketStatus,
            updateTicketStatus,
            blankTicketStatus,
            ticketStatus,
            clearTicketStatusData,
            handleTicketStatusClick,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
