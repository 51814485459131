import { ref, watch, computed } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default function useUsersList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        {
            key: "id",
            sortable: false,
        },
        {
            key: "name",
            sortable: false,
        },
        {
            key: "label",
            sortable: false,
        },
        {
            key: "variant",
            sortable: false,
        },
        {
            key: "createDate",
            sortable: false,
        },
        {
            key: "updatedDate",
            sortable: false,
        },
    ];
    const perPage = ref(10);
    const totalTicketStatuses = ref(null);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const tableLoading = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;

        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalTicketStatuses.value,
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    const fetchTicketStatuses = (ctx, callback) => {
        store
            .dispatch("ticket-status/fetchTicketStatuses", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                role: roleFilter.value,
                plan: planFilter.value,
                status: statusFilter.value,
            })
            .then(response => {
                if (response.status === 204) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Check variant.",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                    return;
                }
                const { ticketStatuses, total } = response.data;
                tableLoading.value = false;
                callback(ticketStatuses);
                totalTicketStatuses.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error getting ticket statuses",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });
    };

    return {
        fetchTicketStatuses,
        tableColumns,
        perPage,
        currentPage,
        totalTicketStatuses,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        tableLoading,
        refetchData,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    };
}
